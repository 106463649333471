import React, { Component } from "react";
import { appInsights } from "../../components/AppInsights/AppInsights";

import { IPrismicAboutUsPage, IPrismicTeamMember } from "../prismic.model";

import * as styles from "./AboutUs.module.scss";

interface IProps {
  prismicData: IPrismicAboutUsPage;
  teamMembers: IPrismicTeamMember[];
}

export default class AboutUs extends Component<IProps> {
  componentDidMount() {
    appInsights.trackPageView();
  }

  render() {
    const { prismicData, teamMembers } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.headingContainer}>
          <p className={styles.heading}>{prismicData.about_us.text}</p>
        </div>
        <div className={styles.descriptionContainer}>
          <p className={styles.subtitle}>{prismicData.subtitle.text}</p>
          <div className={styles.description}>
            {prismicData.paragraph_1.text && (
              <p>{prismicData.paragraph_1.text}</p>
            )}
            {prismicData.paragraph_2.text && (
              <p>{prismicData.paragraph_2.text}</p>
            )}
            {prismicData.paragraph_3.text && (
              <p>{prismicData.paragraph_3.text}</p>
            )}
            {prismicData.paragraph_4.text && (
              <p>{prismicData.paragraph_4.text}</p>
            )}
          </div>
        </div>
        {/* <div className={styles.partnersContainer}>
          <div className={styles.headingContainer}>
            <p className={styles.heading}>{prismicData.partners.text}</p>
          </div>
          <img
            className={styles.partnersImage}
            src={prismicData.partners_image.url}
            alt={prismicData.partners.text}
          />
        </div> */}
        <div className={styles.headingContainer}>
          <p className={styles.heading}>{prismicData.team.text}</p>
          <p className={styles.subtitle}>{prismicData.team_description.text}</p>
        </div>
        <div className={styles.membersContainer}>
          {teamMembers.map((member) => {
            return (
              <div className={styles.teamMember} key={member.ordinal_number}>
                <img
                  className={styles.memberPhoto}
                  src={member.photo.url}
                  alt={member.member_name.text}
                />
                <div className={styles.memberInfo}>
                  <p className={styles.memberName}>{member.member_name.text}</p>
                  <p className={styles.memberPosition}>
                    {member.position.text}
                  </p>
                  <a
                    className={styles.memberContact}
                    href={`mailto:${member.contact_link.text}`}
                  >
                    {member.contact.text}
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
