import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

import Header from "../components/Header";

import {
  IPrismicHeader,
  IPrismicFooter,
  IPrismicAboutUsPage,
  IPrismicTeamMember,
} from "../scenes/prismic.model";
import Footer from "../components/Footer";
import { FAVICON } from "../utils/constants";
import AboutUs from "../scenes/AboutUs";

interface IProps {
  data: {
    prismicHeader: {
      data: IPrismicHeader;
    };
    prismicAboutUs: {
      data: IPrismicAboutUsPage;
    };
    allPrismicTeamMembers: {
      edges: any[];
    };
    prismicFooter: {
      data: IPrismicFooter;
    };
  };
  pageContext: {
    locale: string;
    name: string;
  };
}

export default function AboutPage(props: IProps) {
  const mapMembers = (): IPrismicTeamMember[] => {
    return props.data.allPrismicTeamMembers.edges
      .map((edge: any) => edge.node.data)
      .sort((a: IPrismicTeamMember, b: IPrismicTeamMember) => {
        return a.ordinal_number - b.ordinal_number;
      });
  };

  return (
    <>
      <Helmet>
        <title>matchHR</title>
        <link rel="shortcut icon" href={FAVICON} type="image/x-icon" />
      </Helmet>
      <Header
        data={props.data.prismicHeader.data}
        pageContext={props.pageContext}
      />
      <AboutUs
        prismicData={props.data.prismicAboutUs.data}
        teamMembers={mapMembers()}
      />
      <Footer data={props.data.prismicFooter.data} />
    </>
  );
}

export const pageQuery = graphql`
  query prismicAboutUsPageQuery($locale: String!) {
    prismicHeader(lang: { eq: $locale }) {
      data {
        how_it_works_link {
          text
        }
        pricing_link {
          text
        }
        contact_us_link {
          text
        }
        about_us_link {
          text
        }
        login_link {
          text
        }
        signup_link {
          text
        }
        book_demo_link {
          text
        }
        get_started_link {
          text
        }
      }
    }
    prismicAboutUs(lang: { eq: $locale }) {
      data {
        about_us {
          text
        }
        subtitle {
          text
        }
        paragraph_1 {
          text
        }
        paragraph_2 {
          text
        }
        paragraph_3 {
          text
        }
        paragraph_4 {
          text
        }
        partners {
          text
        }
        partners_image {
          url
        }
        team {
          text
        }
        team_description {
          text
        }
      }
    }
    allPrismicTeamMembers(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          data {
            ordinal_number
            member_name {
              text
            }
            position {
              text
            }
            contact {
              text
            }
            contact_link {
              text
            }
            photo {
              url
            }
          }
        }
      }
    }
    prismicFooter(lang: { eq: $locale }) {
      data {
        footer_heading {
          text
        }
        footer_label {
          text
        }
        get_started_button {
          text
        }
        copyright {
          text
        }
        contact_us_link {
          text
        }
        privacy_policy_link {
          text
        }
      }
    }
  }
`;
